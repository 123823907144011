<template>
  <div class="convenience">
    <el-tabs class="taps-card" v-model="form.categoryId" @tab-click="tabsClick">
      <el-tab-pane
        v-for="(item, index) in category"
        :key="index"
        :label="item.categoryName"
        :name="item.id"
      ></el-tab-pane>
    </el-tabs>
    <div class="search-container">
      <el-row>
        <el-col :span="8">
          <el-form ref="form" :model="form" label-width="0px">
            <el-form-item prop="searchValue">
              <el-input clearable v-model="form.searchValue" placeholder="请输入关键字"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="16" style="text-align:right">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" align="center" fixed="left" width="55"></el-table-column>
        <el-table-column
          prop="title"
          align="center"
          header-align="center"
          show-overflow-tooltip
          min-width="165"
          label="标题"
        >
          <template slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <img :src="scope.row.coverPic" style="height:50px; width: 50px; float:left" alt />
              <div>{{scope.row.title}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="publishBy" min-width="148" align="center" label="发布者"></el-table-column>

        <el-table-column prop="publishTime" align="center" min-width="168" label="发布时间"></el-table-column>

        <el-table-column
          prop="clickCount"
          sortable="custom"
          align="center"
          min-width="115"
          label="实际阅读量"
        ></el-table-column>

        <el-table-column
          prop="collectCount"
          align="center"
          min-width="115"
          sortable="custom"
          label="收藏人数"
        ></el-table-column>

        <el-table-column prop="top" min-width="110" align="center" label="是否置顶">
          <template slot-scope="scope">{{ 'Y' == scope.row.top? '是':'否' }}</template>
        </el-table-column>

        <el-table-column label="操作" min-width="115" align="center">
          <template slot-scope="scope">
            <el-button class="default-button" v-auth="'fitnessFacilities:facilityManagement:edit'" @click="changeAddDialog(true, true, [{id:scope.row.id}])">编辑</el-button>
            <el-button class="default-button" v-auth="'fitnessFacilities:facilityManagement:publish'" v-if="isPublishable(scope.row)" @click="openDeleteDialog([scope.row.id], 9)">上架</el-button>
            <el-button class="default-button" v-auth="'fitnessFacilities:facilityManagement:publish'" v-if="!isPublishable(scope.row)" @click="openDeleteDialog([scope.row.id], 17)">下架</el-button>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <div>
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('fitnessFacilities:facilityManagement:add')"
          @click="changeAddDialog(true)"
        >{{$t('add')}}</el-button>
        <!-- <el-button
          class="default-button"
          v-if="buttonAuth.includes('fitnessFacilities:facilityManagement:edit')"
          :disabled="selectedArr.length !== 1"
          @click="changeAddDialog(true, true, selectedArr)"
        >{{$t('edit')}}</el-button> -->
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('fitnessFacilities:facilityManagement:remove')"
          :disabled="selectedArr.length < 1"
          @click="deleteItem"
        >{{$t('delete')}}</el-button>
        <el-button
          class="default-button"
          @click="openDeleteDialog(selectedArr.map(item => item.id), 9)"
          :disabled="matchStatus===2||selectedArr.length ===0"
          v-if="buttonAuth.includes('fitnessFacilities:facilityManagement:publish') && (matchStatus===0||matchStatus===2)"
        >上架</el-button>
        <el-button
          class="default-button"
          @click="openDeleteDialog(selectedArr.map(item => item.id), 17)"
          v-if="buttonAuth.includes('fitnessFacilities:facilityManagement:publish') && matchStatus===1"
        >下架</el-button>
        <el-button
          class="default-button"
          @click="openDeleteDialog(selectedArr.map(item => item.id).join(','), 18)"
          :disabled="topStatus===2||selectedArr.length ===0"
          v-if="buttonAuth.includes('fitnessFacilities:facilityManagement:top') && (topStatus===0||topStatus===2)"
        >置顶</el-button>
        <el-button
          class="default-button"
          @click="openDeleteDialog(selectedArr.map(item => item.id).join(','), 19)"
          v-if="buttonAuth.includes('fitnessFacilities:facilityManagement:top') && topStatus===1"
        >取消置顶</el-button>
      </div>
      <!-- </div>
      <div class="page-container">-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
    ></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
// import IndexClass from "./indexClass";
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";
import addDialog from "./dialog/addDialog.vue";
import confirmDialog from "@/components/confirmDialog.vue";
import pageClass from "@/unit/pageClass";
import createDeleteMixin from '@/mixins/createDeleteMixin.js'

class IndexClass extends pageClass {
  constructor(type) {
    super();
    if (type === "form") {
      this.categoryId = "";
      this.searchValue = "";
    }
  }
}

export default {
  mixins: [mixin, createDeleteMixin(apis.fitnessDeviceManage)],
  components: { addDialog, confirmDialog },
  data() {
    return {
      form: new IndexClass("form"), // 实例化一个表单的变量
      status: "",
      tableData: [],
      category: [], // tab栏
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      matchStatus: 0, // 0 发布 1 下架 2 禁用
      topStatus: 0, // 0 置顶 1 取消置顶 2 禁用
    };
  },
  mounted() {
    this.$http.get(apis.fitnessDeviceManageGetIdAndName).then((res) => {
      if (res.data.code === 0) {
        this.category = res.data.data;
        this.form.categoryId = this.category[0].id;
        this.searchEvent();
      }
    });
  },
  activated() {
    this.$http.get(apis.fitnessDeviceManageGetIdAndName).then((res) => {
      if (res.data.code === 0) {
        this.category = res.data.data;
      }
    });
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  methods: {
    // tabs切换
    tabsClick() {
      this.searchEvent();
    },
    // 表格选中
    handleSelectionChange(arr) {
      this.selectedArr = arr;
      if (
        [...new Set(arr.map(item => item.publishStatus))].length === 1
        && (arr[0].publishStatus === 0 || arr[0].publishStatus === 2)
      ) {
        this.matchStatus = 0;
      } else if (
        [...new Set(arr.map(item => item.publishStatus))].length === 1
        && arr[0].publishStatus === 1
      ) {
        this.matchStatus = 1;
      } else if (
        [...new Set(arr.map(item => item.publishStatus))].length !== 1
      ) {
        this.matchStatus = 2;
      }
      if (
        [...new Set(arr.map(item => item.top))].length === 1
        && arr[0].top === "N"
      ) {
        this.topStatus = 0;
      } else if (
        [...new Set(arr.map(item => item.top))].length === 1
        && arr[0].top === "Y"
      ) {
        this.topStatus = 1;
      } else if ([...new Set(arr.map(item => item.top))].length !== 1) {
        this.topStatus = 2;
      }
    },
    // 清空按钮
    cleanForm() {
      this.$refs.form.resetFields();
    },
    // 查询
    searchData() {
      const formData = { ...this.form };
      this.$http.post(apis.fitnessDeviceManageList, formData).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        }
      });
    },
    statusFormatter(row, column, cellValue) {
      let name = "";
      if (cellValue === 0) {
        name = "未发布";
      } else if (cellValue === 1) {
        name = "已发布";
      } else if (cellValue === 2) {
        name = "已下架";
      }
      return name;
    },
    // 是否置顶自定义转换
    topFormatter(row, column, cellValue) {
      let name = "";
      if (cellValue === "Y") {
        name = "是";
      } else if (cellValue === "N") {
        name = "否";
      }
      return name;
    },
    // 新增修改
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["addDialog"].getDataQuery(data);
        } else {
          this.$refs['addDialog'].isAdd = true;
          setTimeout(() => {
            this.$refs['addDialog'].form.categoryId = this.form.categoryId;
          }, 1000);
        }
      } else {
        this.searchData();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
        console.log(this.addDialog.visible);
      });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
        this.searchEvent();
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(id, type) {
      this.confirmDialog.type = type;
      this.confirmDialog.data.ids = id;
      this.changeConfirmDialog(true, type);
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
     if (
        this.confirmDialog.type === 9 ||
        this.confirmDialog.type === 17
      ) {
        this.modelHangOn();
      } else if (
        this.confirmDialog.type === 18 ||
        this.confirmDialog.type === 19
      ) {
        this.topHandle();
      }
    },

    // 上下架
    modelHangOn() {
      let publishStatus = "";
      if (this.confirmDialog.type === 9) {
        publishStatus = 1;
      } else {
        publishStatus = 2;
      }
      this.$http
        .put(apis.fitnessDeviceManageUpdateStatus, {
          ids: this.confirmDialog.data.ids,
          publishStatus: publishStatus,
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (this.confirmDialog.type === 9) {
              this.$message.success("上架成功!");
            } else {
              this.$message.success("下架成功!");
            }
            this.$refs.confirmDialog.closeDialog();
            this.searchData();
          }
        });
    },
    // 置顶、取消置顶
    topHandle() {
      this.$http
        .put(
          `${apis.fitnessDeviceManageUpdateTop}?ids=${this.confirmDialog.data.ids}`
        )
        .then((res) => {
          if (res.data.code === 0) {
            if (this.confirmDialog.type === 18) {
              this.$message.success("置顶成功!");
            } else {
              this.$message.success("取消置顶成功!");
            }
            this.$refs.confirmDialog.closeDialog();
            this.searchData();
          }
        });
    },

    /**
     * 返回文章是否可发布
     */
    isPublishable(item){
      return 0 == item.publishStatus || 2 == item.publishStatus
    }
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "~@/assets/styles/mixin.less";
.convenience {
  .page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}
</style>
