<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      :title="`${isEdit ? '修改' : '新增'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      top="10px"
      center
      :close-on-click-modal="false"
      width="650px"
    >
      <el-tabs class="taps-card" v-model="form.status" @tab-click="handleClick">
        <el-tab-pane label="基础信息" name="first">
          <el-form ref="form" :model="form" label-width="100px" :rules="rules">
            <el-row>
              <el-col :span="24">
                <el-form-item label="标题" prop="title">
                  <el-input v-model="form.title" maxlength="30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="副标题" prop="subtitle">
                  <el-input v-model="form.subtitle" maxlength="60"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="分类" prop="categoryId">
                  <el-select v-model="form.categoryId" placeholder="请选择">
                    <el-option
                      v-for="item in categoryData"
                      :key="item.id"
                      :label="item.categoryName"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <div class="upload-images-tip" style="margin-top: 0px;">一个内容设置一个分类，便于文章归档</div>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="地图图标" prop="mapIconUrl">
                  <el-upload
                    class="upload"
                    v-if="showPic"
                    :show-file-list="false"
                    :with-credentials="true"
                    :action="apis.ImageUpload"
                    :httpRequest="uploadHttpDefault"
                    :headers="{
                      token: TOKEN
                  }"
                    name="file"
                    accept=".png, .jpg"
                    :limit="1"
                    :on-success="uploadSuccess1"
                    :on-error="uploadError"
                  >
                    <img
                      class="image-upload"
                      src="~@/assets/images/upload_image.png"
                      v-if="form.mapIconUrl === ''"
                      alt
                      srcset
                    />
                    <img
                      v-if="form.mapIconUrl"
                      :src="form.mapIconUrl"
                      alt
                      srcset
                      width="50"
                      height="50"
                    />
                    <div class="upload-images-tip">建议尺寸38x44px</div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="封面图片" prop="coverPicUrl">
                  <el-upload
                    class="upload"
                    v-if="showPic"
                    :show-file-list="false"
                    :with-credentials="true"
                    :action="apis.ImageUpload"
                    :httpRequest="uploadHttpDefault"
                    :headers="{
                      token: TOKEN
                  }"
                    name="file"
                    accept=".png, .jpg"
                    :limit="1"
                    :on-success="uploadSuccess"
                    :on-error="uploadError"
                  >
                    <img
                      class="image-upload"
                      src="~@/assets/images/upload_image.png"
                      v-if="form.coverPicUrl === ''"
                      alt
                      srcset
                    />
                    <img
                      v-if="form.coverPicUrl"
                      :src="form.coverPicUrl"
                      alt
                      srcset
                      width="50"
                      height="50"
                    />
                    <div class="upload-images-tip">建议尺寸690x388px</div>
                  </el-upload>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="区域" prop="region">
                  <areaSelect :isAdd="isAdd" :isDefault="isDefault" v-model="form.region" ></areaSelect>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="24">
                <el-form-item label="查询" prop="point" :required="isRequired">
                  <div class="bmap-wrap">
                    <new-map  v-if="showMap" ref="bMap" :address.sync="form.address" :point.sync="form.point" :isEdit="isEdit" @onMapAdress="getRAdress" height="300px"></new-map>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="经度" prop="longitude">
                  <el-input v-model="form.longitude" :disabled="true" maxlength="30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="纬度" prop="latitude" >
                  <el-input v-model="form.latitude" :disabled="true" maxlength="30"></el-input>
                </el-form-item>
              </el-col>-->

              <dialogMap
                v-if="showMap"
                ref="bMap"
                :address.sync="form.address"
                :point.sync="form.point"
                :isEdit="isEdit"
                @onMapAdress="getRAdress"
                :lat.sync="form.latitude"
                :lng.sync="form.longitude"
              ></dialogMap>

              <el-col :span="24">
                <el-form-item label="地址" prop="mapAnnotation">
                  <el-input v-model="form.mapAnnotation" maxlength="100"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="联系电话" prop="phone">
                  <el-input v-model="form.phone" maxlength="30"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="基础阅读量" prop="baseClickCount">
                  <el-input-number v-model="form.baseClickCount" controls-position="right" :min="0"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="置顶" prop="top">
                  <el-radio-group v-model="form.top">
                    <el-radio label="N">不置顶</el-radio>
                    <el-radio label="Y">置顶</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="详细设置" name="second">
          <detailInfo :form.sync="form" ref="forms"></detailInfo>
        </el-tab-pane>
      </el-tabs>

      <template slot="footer">
        <el-button
          class="footer-confirm"
          v-show="form.status === 'first'"
          @click="nextButton"
        >下一步</el-button>
        <el-button class="footer-confirm" v-show="form.status === 'second'" @click="onPreview">预览</el-button>
        <el-button
          class="footer-confirm"
          v-show="form.status === 'second'"
          @click="sureEvent"
        >{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
    <!-- 图片预览 -->
    <preview-image
      ref="previewImage"
      :show="previewImage.visible"
      @closeDialog="() => {this.previewImage.visible=false}"
    ></preview-image>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";
// import Bmap from "@/components/BMap.vue";
// import newMap from "../../venueMana/venue/dialog/bMap.vue";
// import { quillEditor } from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
import previewImage from "./preview.vue";
import dialogMap from "@/components/dialogMap.vue";
import areaSelect from "./areaSelect.vue";
import detailInfo from "./detailInfo.vue";

export default {
  mixins: [mixin],
  components: {
    // quillEditor,
    // newMap,
    previewImage,
    dialogMap,
    areaSelect,
    detailInfo,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  // mounted() {
      // console.log("DROPDOWNBOX.VENUE_SERVICE", this.DROPDOWNBOX.WEEK)
      // console.log('isEd--', this.isEd);
  // },
  data() {
    return {
      isDefault: false,
      isAdd: false,
      categoryData: [], // 分类的下拉框选项
      baseImage: "",
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
      showMap: true,
      isRequired: true,
      showPic: true,
      editfirst: false,
      editorOption: {
        placeholder: "请输入...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["image", "formula"], //去除video即可
          ],
        },
      },
      previewImage: {
        visible: false,
        src: "",
      },

      // activeTab: "second",
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList"]),
  },
  methods: {
    venueChange(e) {
      // 关联服务改变事件
      const nameArr = [];
      const tabArr = [];
      e.forEach((item) => {
        const arr = this.venueList.findIndex(ele => ele.id === item);
        nameArr.push(this.venueList[arr].venueName);
        tabArr.push({
          linkName: "",
          linkUrl: "",
          venueId: this.venueList[arr].id,
          venueName: this.venueList[arr].venueName,
        });
      });
      // 判断选中下拉框里面删掉值，表跟着相应删除
      this.form.omVenueLinkDTOList.forEach((item, index) => {
        if (!nameArr.includes(item.venueName)) {
          this.form.omVenueLinkDTOList.splice(index, 1);
        }
      });
      // 判断选中下拉框里增加值，表跟着相应增加
      nameArr.forEach((item, index) => {
        let isrue = true;
        this.form.omVenueLinkDTOList.forEach((items) => {
          if (items.venueName === item) {
            isrue = false;
          }
        });
        if (isrue) {
          this.form.omVenueLinkDTOList.push({
            linkName: "",
            linkUrl: "",
            remindMsg: "",
            venueId: tabArr[index].venueId,
            venueName: tabArr[index].venueName,
          });
        }
      });
    },
    getRAdress(address, point) {
      console.log("getRAdress-add", address);
      if (!this.editfirst) {
        this.form.mapAnnotation = address.address;
      }
      this.form.point.lat = point.lat;
      this.form.latitude = point.lat;
      this.form.point.lng = point.lng;
      this.form.longitude = point.lng;
      this.editfirst = false;
    },
    /**
     * @function 获取分类的下拉框选项值
     **/
    getOptionData() {
      this.$http.get(apis.fitnessDeviceManageGetIdAndName).then((res) => {
        if (res.data.code === 0) {
          this.categoryData = res.data.data;
        }
      });
    },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init();
      this.$refs.bMap.clearData();
      this.$emit("closeDialog", false);
      this.isDefault = false;
      this.isAdd = false;
    },
    ...mapMutations(["setVenueId", "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.$refs.forms.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    handleClick() {
      if (this.form.status === 'second') {
        this.$refs.forms.init();
      }
      // this.showMap = false
      // this.$nextTick(() => {
      //   this.showMap = true
      //   // if (value.name === "first") {
      //   //   this.$refs.bMap.init()
      //   // }
      // })
    },
    // 地图选点成功返回
    onGetPoint(point, address) {
      // debugger
      this.form.point.lat = point.lat;
      this.form.point.lng = point.lng;
      this.form.address = address;
      // this.$nextTick(() => {
      //       this.form.mapAnnotation = address
      // })
    },
    // 下一步
    nextButton() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.status = "second";
        }
      });
    },
    /**
     * @function 文本编辑器
     */
    onEditorReady(editor) {
      console.log(editor);
    },
    onEditChange() {
      // this.$refs.forms.validateField("detail");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.editfirst = false;
      this.$refs.forms.validate((valida) => {
        if (valida) {
          this.$refs.form.validate((valid) => {
            if (!valid) {
              this.form.status = "first";
              return
            }
            const formData = { ...this.form };
            formData.latitude = formData.point.lat;
            formData.longitude = formData.point.lng;
            formData.coverPic = formData.coverPicUrl;
            formData.mapIcon = formData.mapIconUrl;

            formData.typeInputList = (formData.typeInputList || []).filter(
              (e) => {
                return e.typeId && e.number > 0;
              },
            );

            if (this.isEdit) {
              this.$http.put(apis.fitnessDeviceManage, formData).then((res) => {
                if (res.data.code === 0) {
                  this.closeDialog();
                }
              });
            } else {
              this.$http
                .post(apis.fitnessDeviceManage, formData)
                .then((res) => {
                  if (res.data.code === 0) {
                    this.closeDialog();
                  }
                });
            }
          });
        }
      });
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http
        .get(`${apis.fitnessDeviceManage}/${data[0].id}`)
        .then((res) => {
          if (res.data.code === 0) {
            const resRow = res.data.data
            if (resRow.region === null) {
              this.isDefault = true
            }
            console.log(resRow);
            this.form = {
              ...resRow,
              ...{
                status: "first",
                longitude: resRow.longitude,
                latitude: resRow.latitude,
                point: {
                  lng: Number(resRow.longitude),
                  lat: Number(resRow.latitude),
                },
                typeInputList: resRow.typeMapOutputList,
              },
            };
            if (this.form.typeInputList.length === 0) {
              this.form.typeInputList = new AddDialogClass(
                "form",
              ).typeInputList;
            }

            // this. this.typeMapOutputList
            this.showMap = false;
            this.$nextTick(() => {
              this.showMap = true;
              this.editfirst = true;
            });
          }
        });
    },
    uploadImageBase(file) {
      this.$http
        .post(apis.Base64ImageUpload, { Base64Data: file })
        .then((res) => {
          if (res.data.code === 200) {
            console.log("uploadImageBase", res);
            this.form.venueCircleLogo = res.data.data.path;
          }
        });
    },
    // 封面图片成功
    uploadSuccess(res) {
      this.form.coverPicUrl = res.data.url;
      this.form.coverPic = res.data.path;
      this.showPic = false;
      this.$nextTick(() => {
        this.showPic = true;
      });
      this.$refs.form.validate("coverPicUrl");
    },
    // 封面图片/地图图标失败
    uploadError(res) {
      console.log(res);
    },
    // 地图图标成功
    uploadSuccess1(res) {
      this.form.mapIconUrl = res.data.url;
      this.form.mapIcon = res.data.path;
      this.showPic = false;
      this.$nextTick(() => {
        this.showPic = true;
      });
      this.$refs.form.validate("mapIconUrl");
    },
    openDialog() {
      if (!this.isEdit) {
        this.showMap = false;
        this.$nextTick(() => {
          this.showMap = true;
          // this.$refs.bMap.init()
        });
      }
      this.getOptionData();
    },
    updatePoint(point) {
      this.form.point = point;
      this.showMap = false;
      this.$nextTick(() => {
        this.showMap = true;
      });
      console.log(point);
    },
    // 预览
    onPreview() {
      this.$refs.forms.validate((valida) => {
        if (valida) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.previewImage.visible = true;
              this.$nextTick(() => {
                this.$refs["previewImage"].getPreviewData(this.form);
              });
            } else {
              this.form.status = "first";
            }
          });
        }
      });
    },

    /**
     * 获取省市三季
     */
    // getAreaList() {

    // },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "~@/assets/styles/mixin.less";
.service-wrapper {
  .el-checkbox {
    min-width: 80px !important;
  }
}
.convenienceDialog {
  .ql-editor {
    .ql-editor {
      height: 188px;
    }
  }
  .edit_container {
    .ql-snow {
      .ql-tooltip {
        left: 10px !important;
      }
    }
  }
}
</style>
