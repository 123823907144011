<template>
  <div class="server-detail" style="positon:relative">
    <div class="half-warp"></div>
    <div class="title-class">
      <div class style="display: flex;padding-bottom:28px;">
        <div style="width:546px;">
          <div class="name-class">
            <span>{{details.title? details.title : ''}}</span>
            <div class="click-class">
              <div style="padding-left: 10px; margin-top: -5px; line-height: 44px;">
                <img class="click-icon" style src="@/assets/images/wh_see.png" />
              </div>
              <div class="click-count-text">{{details.clickCount? details.clickCount : ''}}</div>
            </div>
          </div>
          <div class="addrss-class">{{details.mapAnnotation? details.mapAnnotation : ''}}</div>
          <div class="addrss-class phone-class">联系电话：{{details.phone? details.phone : ''}}</div>
        </div>
        <div>
          <img
            style="margin-top:58px;margin-left: 30px; width:74px;height:74px;"
            src="@/assets/images/wh_uncollect.png"
          />
          <!-- <img
            style="margin-top:58px;margin-left: 30px; width:74px;height:74px;"
            @click="changeFavor"
            v-if="details.favorite"
            :src="`~assets/images/wh_colloeted.png`"
          /> -->
        </div>
      </div>
    </div>

    <!-- 健身器材设施情况 -->
    <div class="device-status">
      <span class="title">健身器材设施情况</span>
      <div class="device-item" v-for="(device, index) in fitnessDeviceList" :key="index">
        <img class="cover" :src="device.pictureUrl" />
        <div class="right-container">
          <span class="name">{{ device.deviceName }}</span>
          <span class="count">数量：{{ device.number }}个</span>
        </div>
      </div>
    </div>

    <!-- 详情 -->
    <div class="detail" v-html="details.detail"></div>
    <div class style="width: 100%; height: 100px;"></div>
    <div class="text-class" @click="goPlace">导航去这里</div>
  </div>
</template>
<script>
// import apis from "@/apis.js";
// import { bMapTransQQMap } from "@/utils/map.js";

/**
 * 健身器材设施
 * @typedef {object} FitnessDevice
 * @prop {string} deviceName - 器材名称
 * @prop {string} pictureUrl - 图片地址
 * @prop {number} number - 数量
 */

export default {
  props: {
    data: {
      type: Object,
    },
  },

  data() {
    return {
      details: "",
      id: "",
      /**
       * @type {Array<FitnessDevice>}
       */
      fitnessDeviceList: [],
    };
  },
  watch: {
    data() {
      this.setData(this.data);
    },
  },
  methods: {
    goPlace() {},
    changeFavor() {},

    setData(data) {
      this.details = data;
      this.details.detail = this.details.detail
        .replace(/<img/g, '<img style="width:100%;height:auto;display:block"')
        .replace(/<p/g, '<p style="word-break:break-all;"');

      this.fitnessDeviceList = this.details.typeMapOutputList;
    },
  },
};
</script>
<style lang="less">
// page {
//   background: #f7f8fa;
// }
</style>
<style scoped lang="less">
.server-detail {
  background: #f7f8fa;
  margin-bottom: 100px;
  width: 750px;
    zoom: 0.5;
}
.detail {
  zoom: 2;
  margin: 10px 15px;
  padding: 10px 15px;
  background: #ffffff;
  border-radius: 10px;
}

.half-warp {
  width: 100%;
  height: 100px;
  background: #0479ce;
}
//    .img-class{
//      width:750px;
//      height:452px;
//    }
.text-class {
  position: absolute;
//   top: calc(100vh - 100px);
  bottom: 0;
  width: 100%;
  height: 100px;
  background: #0479ce;
  font-size: 28px;
  font-family: SourceHanSansSC-Regular, SourceHanSansSC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 100px;
  text-align: center;
}
.title-class {
  /* position: absolute; */
  position: relative;
  margin-top: -100px;
  margin-left: 30px;
  width: 690px;
  /* height:188px; */
  background: rgba(255, 255, 255, 1);
  //   box-shadow: 0px 8px 12px 0px rgba(81, 113, 225, 0.08);
  border-radius: 20px;
  /* display:flex; */
  /* padding-bottom: 28px; */
  /*   .daibu {
    height: 80px;
    width: 100%;
    border-radius: 0 0 12px 12px;
    background: linear-gradient(
      180deg,
      rgba(134, 139, 244, 1) 0%,
      rgba(92, 96, 216, 1) 100%
    );
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .ticket {
      width: 346px;
      font-size: 24px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .spitCenter {
      // width: 1px;
      border: 2px solid #ffffff;
      height: 48px;
      background: #ffffff;
    }
    .tea {
      width: 342px;
      font-size: 24px;
      font-family: SourceHanSansSC-Regular, SourceHanSansSC;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  } */
}
.dingButton {
  width: 130px;
  height: 50px;
  font-size: 24px;
  color: #ffffff;
  background: #7377e8;
  padding: 0;
  line-height: 50px;
}
.name-class {
  margin: 28px 0 0 28px;
  width: 518px;
  height: 44px;
  font-size: 30px;
  font-family: SourceHanSansSC-Medium, SourceHanSansSC;
  font-weight: 500;
  color: rgba(14, 20, 38, 1);
  line-height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.addrss-class {
  margin: 10px 0 0 28px;
  /* height:34px; */
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 34px;
}
.phone-class {
}
.click-class {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 40px;

  .click-icon {
    width: 24px;
    height: 16px;
  }

  .click-count-text {
    font-size: 22px;
    color: #999999;
    line-height: 24px;
    margin-left: 4px;
  }
}

//健身器材设施情况
.device-status {
  border-radius: 20px;
  margin: 20px 30px;
  padding: 24px 32px;
  background-color: white;
  .title {
    font-size: 28px;
    line-height: 40px;
    color: #0b1123;
  }

  .device-item {
    display: flex;
    margin-top: 24px;

    .cover {
      width: 100px;
      height: 100px;
      margin-right: 28px;
      display: block;
      flex-shrink: 0;
    }

    .right-container {
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      .name {
        font-size: 28px;
        line-height: 40px;
        color: #0b1123;
      }
      .count {
        font-size: 22px;
        line-height: 32px;
        color: #999;
      }
    }
  }
}
</style>
