/*
 * @Author: your name
 * @Date: 2020-03-23 11:19:40
 * @LastEditTime: 2021-08-26 16:43:20
 * @LastEditors: zhongtao
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\venue\dialog\addDialogClass.js
 */
/* eslint-disable */
class searchFrom {
  validatePhone = (rule, value, callback) => {
    var phone=value.replace(/\s/g, "");//去除空格
    //校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、176~178。14号段为上网卡专属号段
    let regs = /^((13[0-9])|(17[0-1,3,6-8])|(15[^4,\\D])|(18[0-9]))\d{8}$/;
    let reg = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/;
    if(!value){
        callback([new Error('请输入联系电话')]);
    } else {
        if(!regs.test(phone)&&!reg.test(phone)){
            callback([new Error('联系号码输入不合法')]);
        } else {
            callback();
        }
   }
  }
  constructor(type) {
    if (type === 'form') {
      this.title = '';
      this.subtitle = ''
      this.categoryId = ''
      
      this.mapIconUrl=''
      this.coverPicUrl = ''
      this.status = 'first'
      
      this.baseClickCount = ''
      // this.coverPic = ''
      
      this.latitude = ''
      this.longitude = ''
      // this.address = ''
      // this.mapAnnotation = ''
      
      this.phone = ''
      this.top = ''
      this.region = ''
      this.detail = ''
      this.shareCopywriter = ''
      
      // this.mapIconUrl = ''
      this.typeInputList = [{
        typeId: "",
        number: 0,
      }]
      // this.omVenueLinkDTOList = []
      // 自己用
      this.point = {}
    } else if (type === 'rule') {
      this.title = [{ required: true, message: '请输入标题', trigger: 'blur' }]
      this.subtitle = [{ required: true, message: '请输入副标题', trigger: 'blur' }]
      this.categoryId = [{ required: true, message: '请选择分类', trigger: 'change' }]
      this.coverPicUrl = [{ required: true, message: '请选择封面图片', trigger: ['change', 'blur'] }]
      this.mapIconUrl = [{ required: true, message: '请选择地图图标', trigger: ['change', 'blur'] }]
      this.phone = [{ required: true, message: "请输入联系电话", trigger: ['blur', 'change'] },
      { min: 11, max: 11, message: '长度在11个字符', trigger: 'blur' }]
      this.point = [{ required: true, message: '请选择地址', trigger: ['change', 'blur'] }]
      this.baseClickCount = [{ required: true, message: '请输入基础阅读量', trigger: ['change', 'blur'] }]
      this.top = [{ required: true, message: '请选择是否置顶', trigger: ['change', 'blur'] }]
      // this.detail = [{ required: true, message: '请输入详细介绍', trigger: ['change', 'blur'] }]
      // this.mapAnnotation = [{ required: true, message: '请输入地址', trigger: ['change', 'blur'] }]
      this.latitude = [{ required: true, message: '请输入经度', trigger: ['change', 'blur'] }]
      this.longitude = [{ required: true, message: '请输入维度', trigger: ['change', 'blur'] }]
      this.region =  [{ required: true, message: '请选择区域', trigger: ['change', 'blur'] }]
      // this.venueIds = [{ required: true, message: '请选择关联服务', trigger: ['change', 'blur'] }]
    }
  }
}
export default searchFrom
