<!--
 * @Author: Hong Yiqun
 * @LastEditors: Please set LastEditors
-->
<template>
  <div>
    <el-form ref="forms" :model="form" :rules="rules">
      <div>健身器材设置：</div>
      <el-row>
        <el-col :span="4">健身器材名称：</el-col>

        <el-col :span="20">
          <!-- <deviceTypeList v-model="mForm.typeInputList"></deviceTypeList> -->
          <div
            class="device-type-item"
            v-for="(typeItem, index) in mForm.typeInputList"
            :key="index"
          >
            <el-select v-model="typeItem.typeId" placeholder="请选择">
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <span>个数</span>

            <el-input v-model="typeItem.number"></el-input>

            <i class="el-icon-circle-plus-outline" @click="addEmptyItem"></i>
            <i
              class="el-icon-remove-outline"
              v-if="1 < mForm.typeInputList.length"
              @click="removeItem(index)"
            ></i>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24">
          <div>分享文案（选填，微信分享给好友是会显示这里的文案）</div>
          <el-form-item prop="shareCopywriter">
            <el-input v-model="mForm.shareCopywriter" type="textarea" rows="3" maxlength="100"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item prop="detail" label-width="0">
            <div class="edit_container">
              <!-- <quill-editor v-model="mForm.detail" ref="myQuillEditor" class="ql-editor editer" :options="editorOption" @ready="onEditorReady($event)" @change="onEditChange">
              </quill-editor>-->
              <quill-edit
                :detail="mForm.detail"
                @change="onEditChange"
                :qnLocation="apis.ImageUpload"
              ></quill-edit>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
// 健身设施的详细信息
// import newMap from "../../venueMana/venue/dialog/bMap.vue";
import quillEdit from "@/components/quillEdit.vue";
import apis from "@/apis";
// import deviceTypeList from "./deviceTypeList.vue";

export default {
  props: {
    form: {
      type: Object,
      require: true,
    },
  },
  components: {
    quillEdit,
    // deviceTypeList,
  },
  data() {
    return {
      mForm: this.form,
      rules: {},
      typeList: [],
    };
  },
  computed: {
    // editorOption() {
    //   return {
    //     placeholder: "请输入...",
    //     modules: {
    //       toolbar: [
    //         ["bold", "italic", "underline", "strike"],
    //         ["blockquote", "code-block"],
    //         [{ header: 1 }, { header: 2 }],
    //         [{ list: "ordered" }, { list: "bullet" }],
    //         [{ script: "sub" }, { script: "super" }],
    //         [{ indent: "-1" }, { indent: "+1" }],
    //         [{ direction: "rtl" }],
    //         [{ size: ["small", false, "large", "huge"] }],
    //         [{ header: [1, 2, 3, 4, 5, 6, false] }],
    //         [{ color: [] }, { background: [] }],
    //         [{ font: [] }],
    //         [{ align: [] }],
    //         ["image", "formula"], //去除video即可
    //       ],
    //     },
    //   };
    // },
    apis: () => apis,
  },

  watch: {
    form() {
      this.mForm = this.form;
    },
    mForm() {
      this.$emit("update:form", this.mForm);
    },
  },

  mounted() {
    this.addEmptyItem();
    this.init()
  },

  methods: {
    init() {
      this.$http
      .post(apis.fitnessDeviceTypeList, {
        pageNum: 1,
        pageSize: 999,
      })
      .then((res) => {
        this.typeList = res.data.rows;
      });
    },
    onEditChange(detail) {
      //   this.$refs.forms.validateField("detail");
      this.mForm.detail = detail;
    },

    addEmptyItem() {
      this.mForm.typeInputList.push({
        typeId: "",
        number: 0,
      });
    },

    removeItem(index) {
      this.mForm.typeInputList.splice(index, 1);
    },

    validate(arg) {
      this.$refs.forms.validate(arg);
    },

    resetFields(arg) {
      this.$refs.forms.resetFields(arg);
    },
  },
};
</script>

<style lang="scss" scoped>
.device-type-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  > * {
    margin-right: 8px;
  }
  .el-input {
    max-width: 150px;
  }

  .el-icon-circle-plus-outline,
  .el-icon-remove-outline {
    font-size: 24px;
  }
}
</style>
