import { render, staticRenderFns } from "./detailInfo.vue?vue&type=template&id=b97c0aa2&scoped=true&"
import script from "./detailInfo.vue?vue&type=script&lang=js&"
export * from "./detailInfo.vue?vue&type=script&lang=js&"
import style0 from "./detailInfo.vue?vue&type=style&index=0&id=b97c0aa2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b97c0aa2",
  null
  
)

export default component.exports