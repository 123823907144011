<!--
 * @Author: genwai
 * @Date: 2020-05-21 14:07:33
 * @LastEditTime: 2020-09-17 11:17:53
 * @LastEditors: Hong Yiqun
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\component\preview.vue
-->
<template>
  <div class="image-preview-wrapper">
     <el-dialog
      :visible.sync="show"
      :before-close="closeDialog"
      center
      top="30"
      :show-close="false"
      class="image-preview-applet-content"
      :close-on-click-modal="false"
      width="100%">
      <div class="out-wrapper">
        <div class="img-wrapper">
          <div class="applet-wrapper">
            <div class="applet-head">
              <img src="@/assets/images/pre_top.png" alt="" srcset="">
            </div>
            <!-- <div class="applet-content">
              <img class="image" :src="form.coverPicUrl" alt="">
              <div class="collection">
                <img style="margin-right: 5px;" src="@/assets/images/eyes.png" alt="">
                <div>{{form.baseClickCount}}</div>
              </div>
              <div class="applet-contaner">
                <div class="course-infor">
                  <div class="infor-top">
                    <div class="left">
                      <div class="name">{{form.title}}</div>
                      <pre class="address">地    址：{{form.mapAnnotation}}</pre>
                      <div class="price">联系电话：{{form.phone}}</div>
                    </div>
                    <div class="right">
                      <img class="images" src="@/assets/images/pre-ico.png" alt="">
                    </div>
                  </div>
                </div>
                <div class="course-content">
                  <div class="ql-editor content" v-html="form.detail"></div>
                </div>
              </div>
            </div> -->
            <!-- <div class="couse-bottom">导航去这里</div> -->
            <detail :data="form"></detail>
          </div>
          <div class="preview-over" @click="closeDialog">结束预览</div>
        </div>
      </div>
     </el-dialog>
  </div>
</template>

<script>
// import apis from '@/apis'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass.js'
import detail from './detail.vue'

export default {
  mixins: [mixin],
  //部件
  components: {
    detail,
  },
  //静态
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    // src: {
    //   type: String,
    //   default: "",
    // },
    // Language: {
    //   required: false,
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      form: new AddDialogClass('form'),
    }
  },

  methods: {
    closeDialog() {
      this.$emit('closeDialog', false)
    },
    /**
     * @public
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getPreviewData(preData) {
      console.log("getDataQuery -> data111111", preData)
      this.form = {
        ...this.form,
        ...preData,
        price: Number(preData.price).toFixed(2),
      }
    },

  },
  //请求数据
  created() {},
  mounted() {},
};
</script>
<style>
.content img {
  width: 100%;
}
</style>
<style lang="less" scoped>
.image-preview-applet-content {
  .out-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .match-items-wrapper {
    width: 375px;
    margin-right: 20px;
		padding: 10px 15px 0;
		.match-item {
			display: flex;
			flex-direction: row;
			background: #fff;
			padding: 8px;
			margin-bottom: 10px;
			.images {
				width: 138px;
				height: 90px;
				margin-right: 10px;
				flex-shrink: 0;
			}
			.right {
				display: flex;
				height: 90px;
				flex-wrap: wrap;
				.top {
					font-weight: bold;
					color: rgba(23, 32, 66, 1);
					line-height: 19px;
					font-size: 13px;
					width: 100%;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
				}
				.item-center {
					font-size: 12px;
					font-weight: 400;
					color: rgba(153, 153, 153, 1);
					margin-bottom: 19px;
					width: 100%;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 1;
					overflow: hidden;
				}
				.btn {
					width: 71px;
					height: 25px;
					line-height: 25px;
					font-size: 12px;
					text-align: center;
					color: rgba(61, 100, 236, 1);
					border-radius: 12px;
					border: 1px solid rgba(61, 100, 236, 1);
					align-self: flex-end;
				}
				.btn-over {
					width: 71px;
					height: 25px;
					line-height: 25px;
					font-size: 12px;
					text-align: center;
					color: rgba(153, 153, 153, 1);
					border-radius: 12px;
					border: 1px solid rgba(153, 153, 153, 1);
				}
			}
		}
	}
  .img-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .preview-over {
    cursor: pointer;
    height:49px;
    width: 375px;
    line-height: 49px;
    background:rgba(61,100,236,1);
    border-radius:25px;
    font-weight:400;
    color:rgba(255,255,255,1);
    font-size:14px;
    text-align: center;
    margin-top: 20px;
  }
  .applet-wrapper {
    width: 375px;
    min-height: 667px;
    background: #F7F8FA;
    position: relative;
  }
  .applet-head {
    background: #3D64EC;
    height: 64px !important;
    height: 100%;
    position: relative;
    .name {
      font-size:17px;
      font-weight:400;
      color:rgba(255,255,255,1);
      line-height:24px;
      position: absolute;
      left: 33px;
      bottom: 11px;
    }
  }

  .course-content {
    min-height: 250px;
    margin: 20px 15px;
  }
  // .couse-bottom {
  //   width:375px;
  //   height:50px;
  //   background:rgba(83,90,239,1);
  //   font-size:14px;
  //   font-family:SourceHanSansSC-Regular,SourceHanSansSC;
  //   font-weight:400;
  //   color:rgba(255,255,255,1);
  //   text-align: center;
  //   line-height: 50px;
  // }
}
</style>
<style lang="less">
.image-preview-applet-content {
  .el-dialog {
    background: transparent !important;
  }
  .el-dialog__header {
    border-bottom: 0 !important;
  }
}

</style>
