<template>
  <div>
    <el-col :span="24">
      <el-form-item label="详细地址:" prop="point" :required="true">
        <div class="bmap-wrap">
          <new-map
            v-if="showMap"
            ref="bMap"
            :address.sync="mAddress"
            :point.sync="mPoint"
            :isEdit="isEdit"
            @getPoint="onGetPoint"
            @getAddress="mRegion = $event"
            @getRegion="getRegion"
            @onMapAdress="onMapAdress"
            height="300px"
          ></new-map>
        </div>
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="经度:" prop="longitude">
        <el-input v-model="mLng" :disabled="false" maxlength="30"></el-input>
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="纬度:" prop="latitude">
        <el-input v-model="mLat" :disabled="false" maxlength="30"></el-input>
      </el-form-item>
    </el-col>
  </div>
</template>

<script>
/**
 * 弹窗上的地图组件，带输入地址匹配经纬度、经纬度反向查询地址功能
 */

import newMap from "@/views/venueMana/venue/dialog/bMap.vue";
import { each, clone } from "lodash";
import loadBMap from "@/views/venueMana/venue/dialog/loadBMap.js";

/**
 * 用于批量创建双向绑定的组件
 * keyMap 的 key 是prop的名字，value是对应data的名字
 */
function getBindSyncProp(keyMap) {
  const props = {};
  const data = {};
  const watch = {};
  each(keyMap, (dataKey, propKey) => {
    props[propKey] = "";
    data[dataKey] = "";

    watch[propKey] = function watchProps(val) {
      this[dataKey] = val;
    };

    watch[dataKey] = function watchData(val) {
      this.$emit(`update:${propKey}`, val);
    };
  });

  return {
    props,
    data() {
      return clone(data);
    },
    watch,
    created() {
      each(keyMap, (dataKey, propKey) => {
        this[dataKey] = this[propKey];
      });
    },
  };
}

export default {
  mixins: [
    getBindSyncProp({
      address: "mAddress",
      point: "mPoint",
      lat: "mLat",
      lng: "mLng",
      region: "mRegion",
    }),
  ],
  props: {
    showMap: {
      type: Boolean,
      default: () => true,
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      mPoint: {
        lat: 0,
        lng: 0,
      },
    };
  },
  watch: {
    async mPoint() {
      await loadBMap();
      const point = new window.BMap.Point(this.mPoint.lng, this.mPoint.lat);
      console.log(point);
      this.$refs.bMap.getAddrByPoint(point);
    },

    lat() {
      this.mPoint = {
        lat: this.lat,
        lng: this.lng,
      };
    },

    lng() {
      this.mPoint = {
        lat: this.lat,
        lng: this.lng,
      };
    },
  },
  components: {
    newMap,
  },

  methods: {
    onGetPoint(point) {
      this.mLat = point.lat;
      this.mLng = point.lng;
    },
    onMapAdress(address, point) {
      this.$emit("onMapAdress", address, point);
    },
    getRegion(region) {
      this.$emit("getRegion", region);
    },

    /**
     * @public
     */
    init() {
      this.$refs.bMap.init();
    },
    /**
     * @public
     */
    clearData() {
      this.$refs.bMap.clearData();
    },
  },
};
</script>
