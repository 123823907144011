<template>
  <div class="area-select">
    <el-select v-model="lv1AreaId" placeholder="请选择">
      <el-option v-for="item in lv1AreaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>

    <el-select v-model="lv2AreaId" placeholder="请选择">
      <el-option v-for="item in lv2AreaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>

    <el-select v-model="lv3AreaId" placeholder="请选择">
      <el-option v-for="item in lv3AreaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>

    <!-- <el-select v-model="lv4AreaId" placeholder="请选择">
      <el-option v-for="item in lv4AreaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>-->
  </div>
</template>

<script>
import apis from "@/apis";
import { find } from "lodash";

export default {
  props: {
    /**
     * lv3 的id
     */
    value: {
      type: Number,
      default: () => "",
    },
    isDefault: {
      required: true,
      type: Boolean,
    },
    isAdd: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      // options: [],
      rawArea: [],
      lv1AreaId: "",
      lv2AreaId: "",
      lv3AreaId: "",
      // lv4AreaId: "",
      // region: this.value,
    };
  },
  computed: {
    lv1AreaList() {
      return this.rawArea.filter(e => e.pid === 0);
    },

    lv2AreaList() {
      return this.rawArea.filter(e => e.pid === this.lv1AreaId);
    },

    lv3AreaList() {
      return this.rawArea.filter(e => e.pid === this.lv2AreaId);
    },

    // lv4AreaList() {
    //   return this.rawArea.filter(e => e.pid === this.lv3AreaId);
    // },
  },

  watch: {
    lv1AreaId() {
      //   this.$emit("input", this.lv1AreaId);
    },
    lv2AreaId() {
      //   this.$emit("input", this.lv2AreaId);
    },
    lv3AreaId() {
      this.$emit("input", this.lv3AreaId);
    },
    lv4AreaId() {
      //   this.$emit("input", this.lv4AreaId);
    },

    lv2AreaList() {
      if (
        this.lv2AreaList.filter(e => e.id === this.lv2AreaId).length === 0
      ) {
        this.lv2AreaId = "";
      }
    },
    lv3AreaList() {
      if (
        this.lv3AreaList.filter(e => e.id === this.lv3AreaId).length === 0
      ) {
        this.lv3AreaId = "";
      }
    },

    isDefault() {
      if (this.isDefault) {
        this.showDefaultArea();
      }
      this.onValueChange();
    },
    value() {
        // debugger
        this.onValueChange();
    },
    isAdd() {
      this.showDefaultArea();
      this.onValueChange();
    },
  },

  methods: {
    showDefaultArea() {
      if (this.rawArea.length !== 0) {
          setTimeout(() => {
            this.lv1AreaId = 420000;
            this.lv2AreaId = 420100;
          }, 500)
      }
    },
    onValueChange() {
      const lv3 = find(this.rawArea, e => e.id === Math.floor(this.value));
      // if (!lv3) {
      //   this.lv1AreaId = "";
      //   this.lv2AreaId = "";
      //   this.lv3AreaId = "";
      // }
      const lv2 = find(this.rawArea, e => e.id === lv3.pid);
      this.lv1AreaId = lv2.pid;
      this.lv2AreaId = lv2.id;
      this.lv3AreaId = lv3.id;
    },
  },

  mounted() {
    /**
     * 获取省市三级联动
     */
    this.$http
      .get(apis.dist_List, {
        // params: this.form,
      })
      .then((res) => {
        if (res.data.code === 0) {
          this.rawArea = res.data.rows || [];
          if (!this.isDefault) {
            if (this.rawArea.length !== 0) {
                setTimeout(() => {
                  this.lv1AreaId = 420000;
                  this.lv2AreaId = 420100;
                }, 500)
            }
          }
          this.onValueChange();
        }
      });
  },
};
</script>

<style lang="scss">
.area-select {
  display: flex;
  > * {
    margin-right: 8px;
  }
}
</style>
